/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useState } from 'react';
import FromSection from './FormSection'
import Navbar from './Navbar'
import BottomNav from './BottomNav'
import Footer from './Footer'
import '@brightoak/embrk-react-components/dist/index.css'

import './all.sass'

const Layout = ({ children }) => {
  const [triggerPopUp, setTriggerPopUp] = useState(false);
  const [triggerConfPopUp, setTriggerConfPopUp] = useState(false);

  return (
    <>
      <div className="site-content">
        <FromSection showPupUp={triggerPopUp} forceShowConf={triggerConfPopUp} forceShowPupUp={() => setTriggerPopUp(true)} forceClosePopUp={() => setTriggerPopUp(false)} checkForSingUp={true} />
        <Navbar popUpCallback={ () => setTriggerPopUp(true) }/>
        <div>{children}</div>
        <BottomNav/>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
