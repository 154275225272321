import React from 'react'
import PropTypes from 'prop-types'
import InputMask from "react-input-mask";
import { FaRegCheckCircle } from 'react-icons/fa'
import $ from 'jquery';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key].value ? data[key].value : data[key]))
    .join("&");
}

const ConsultationForm = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { first: { value: "", valid: false }, last: { value: "", valid: false }, phone: { value: "", valid: false }, email: { value: "", valid: false } };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    $.ajax({
      url: 'https://script.google.com/macros/s/AKfycbwvcwFPeUDQQ_e829hgDE5EZ2PWXzWR6ezroUDm2IJKLJInO1hq8113weCe6_l1sYy0Bg/exec',
      type: 'post',
      data: {
        "FirstName": this.state.first.value,
        "LastName": this.state.last.value,
        "Email": this.state.email.value,
        "Phone": this.state.phone.value
      }, success: function () {
        console.log('Thanks for signing up');
      }, error: function (error) {
        console.log('error', error);
      }
    });

    window.fbq('track', 'Lead');
    const userString = JSON.stringify({
      "first": this.state.first.value,
      "last": this.state.last.value,
      "email": this.state.email.value,
      "phone": this.state.phone.value
    });
    window.localStorage.setItem("user", userString);
    let onSubmitCallback = this.props.onSubmitCallback;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "consultation", ...this.state })
    })
      .then(() => {
        window.gtag('event', 'conversion', { 'send_to': 'AW-791361717/WgrQCIKjpNcBELXxrPkC' });
        onSubmitCallback();
      })
      .catch(error => alert(error));

    let data = {
      "lead": {
        "first": this.state.first.value,
        "last": this.state.last.value,
        "email": this.state.email.value,
        "phone": this.state.phone.value
      }
    };

    fetch('https://keffect-lead-nurturer.herokuapp.com/api/leads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Api-Key": "ddaf35a193617abacc417349ae20413112e6fa4e89a97ea20a9eeee64b55d39a2192992a274fc1a836ba3c23a3feebbd454d4423643ce80e2a9ac94fa54ca49f"
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    e.preventDefault();
  };

  handleChange = e => {
    if (e.target.name === "first" || e.target.name === "last") {
      this.setState({ [e.target.name]: { value: e.target.value, valid: e.target.value !== "" } });
    } else if (e.target.name === "phone") {
      if (e.target.value[0] == "1") {
        e.target.value = e.target.value.substr(1);
      } else if (e.target.value[0] == "+") {
        e.target.value = e.target.value.substr(2);
      } else if (e.target.value.substring(0, 2) == "(1") {
        return;
      }
      this.setState({ [e.target.name]: { value: e.target.value, valid: e.target.value !== "" && e.target.value.indexOf("_") === -1 } });
    } else if (e.target.name === "email") {
      let emailReg = /\S+@\S+\.\S+/;
      this.setState({ [e.target.name]: { value: e.target.value, valid: emailReg.test(e.target.value) } });
    }
  }

  render() {
    const { first, last, phone, email } = this.state;
    return (
      <div className="contact-form">
        <form onSubmit={this.handleSubmit}>
          {/* <form onSubmit={this.handleSubmit} name="consultation" data-netlify="true" data-netlify-honeypot="bot-field"> */}
          <input type="hidden" name="form-name" value="consultation" />
          <input name="bot-field" style={{ opacity: 0, height: 0, margin: 0 }} />
          <div className="name-row">
            <div className="input-container">
              <input className="left" type="text" name="first" value={first.value} onChange={this.handleChange} placeholder="First Name" one required />
              {this.state.first.valid ? <div className="check-container animate__animated animate__bounceIn"><FaRegCheckCircle /></div> : ""}
            </div>
            <div className="input-container">
              <input className="right" type="text" name="last" value={last.value} onChange={this.handleChange} placeholder="Last Name" required />
              {this.state.last.valid ? <div className="check-container animate__animated animate__bounceIn"><FaRegCheckCircle /></div> : ""}
            </div>
          </div>
          <div className="input-container">
            <InputMask mask={phone.value ? "(999) 999-9999" : ""} name="phone" value={phone.value} onChange={this.handleChange} placeholder="Phone Number" required />
            {this.state.phone.valid ? <div className="check-container animate__animated animate__bounceIn"><FaRegCheckCircle /></div> : ""}
          </div>
          <div className="input-container">
            <input type="email" name="email" value={email.value} onChange={this.handleChange} placeholder="Email Address" required />
            {this.state.email.valid ? <div className="check-container animate__animated animate__bounceIn"><FaRegCheckCircle /></div> : ""}
          </div>
          <button className="btn-filled mobile-hide" type="submit">Sign Up</button>
          <button className="btn-filled desktop-hide" type="submit">Click Here for 1 FREE Class</button>
        </form>
      </div>
    )
  }
}

ConsultationForm.propTypes = {
  onSubmitCallback: PropTypes.func,
}

export default ConsultationForm
