import React from 'react'
import PropTypes from 'prop-types'
import SentIcon from '../images/sent-icon.inline.svg'

const FormConfrimation = class extends React.Component {
// const FormConfrimation = ({closeClallback}) => (

  // <div className="form-confrimation">
  //   <h2>Request Sent!</h2>
  //   <p>
  //     Someone from the K Effect team will be in touch with you shortly.
  //   </p>
  //   <SentIcon/>
  //   <div className="btn-filled" onClick={() => closeClallback != null && closeClallback() }>Close</div>
  // </div>

  constructor(props) {
    super(props);
    this.state = {
      userInfo: ""
    };
  }

  componentDidMount() {
    let user = JSON.parse(window.localStorage.getItem('user'));
    if (user) {
      this.setState({userInfo: "&firstName=" + user.first + "&lastName=" + user.last + "&phone=" + user.phone + "&email=" + user.email});
    }
  }

  render() {
    return (
    <div className="form-confrimation">
      <iframe src={"https://app.acuityscheduling.com/schedule.php?owner=19549336" + this.state.userInfo} title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
    </div>
  )}
}

FormConfrimation.propTypes = {
    closeClallback: PropTypes.func,
}

export default FormConfrimation
