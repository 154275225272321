import React from 'react'
import { Link } from 'react-scroll'
import { PopUp } from '@brightoak//embrk-react-components'
import Logo from '../images/logo.inline.svg'
import Bars from '../images/bars.inline.svg'
import PropTypes from 'prop-types'
import { FaRegEnvelope} from 'react-icons/fa'

const Navbar = class extends React.Component {
  state = {
    active: false
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      }
    )
  }

  scrollLinkClicked = () => {
    if (window.location.pathname != "/" && window.location.pathname != "" ) {
      window.location.href = window.location.origin
    }
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a href="/">
              <Logo/>
            </a>
          </div>
          <div
            id="navMenu"
            className="navbar-menu"
          >
            <div className="navbar-end has-text-centered">
              <div className="links top-nav">
                <Link to="scroll-bands" smooth={true}>
                  <span id="scroll-link-bands" className="page-scroll-link" onClick={() => this.scrollLinkClicked()}>K Effect Bands</span>
                </Link>
                <Link to="scroll-app" smooth={true}>
                  <span id="scroll-link-app" className="page-scroll-link" onClick={() => this.scrollLinkClicked()}> K Effect App</span>
                </Link>
                <Link to="scroll-k-effect" smooth={true}>
                  <span id="scroll-link-k-effect" className="page-scroll-link" onClick={() => this.scrollLinkClicked()}>The Köhler Effect</span>
                </Link>
                <div className="verticle-divide"></div>
                <a className="phone-number" href="tel:801-893-7328">801.893.7328</a>
                <a className="company-email" href="mailto:brickyard@keffecttraining.com"><FaRegEnvelope/></a>
                <div className="btn" onClick={() => this.props.popUpCallback != null && this.props.popUpCallback() }>
                  <span>Try it for Free</span>
                </div>
              </div>
              <div className="mobile-menu-bars" onClick={() => this.toggleHamburger()}>
                <Bars/>
              </div>
            </div>
          </div>
        </div>
        <PopUp forceShowPopUp={this.state.active} forceClosePopUp={() => this.toggleHamburger()} >
          <div className="mobile-pop-up">
            <div className="navbar-brand">
              <Logo/>
            </div>
            <Link to="scroll-bands" smooth={true} onClick={() => this.scrollLinkClicked() && this.toggleHamburger()}>
              <span id="scroll-link-bands" className="page-scroll-link">K Effect Bands</span>
            </Link>
            <Link to="scroll-app" smooth={true} onClick={() =>  this.scrollLinkClicked() && this.toggleHamburger()}>
              <span id="scroll-link-app" className="page-scroll-link">K Effect App</span>
            </Link>
            <Link to="scroll-k-effect" smooth={true} onClick={() =>  this.scrollLinkClicked() && this.toggleHamburger()}>
              <span id="scroll-link-k-effect" className="page-scroll-link">The Köhler Effect</span>
            </Link>
            <a className="phone-number" href="tel:801-893-7328">801.893.7328</a>
            <a className="company-email" href="mailto:brickyard@keffecttraining.com"><FaRegEnvelope/></a>
            <div className="btn" onClick={() => {
              this.toggleHamburger();
              this.props.popUpCallback != null && this.props.popUpCallback();
            } }>
              Try it for Free
            </div>
          </div>
        </PopUp>
      </nav>
    )
  }
}

Navbar.propTypes = {
  popUpCallback: PropTypes.func
}

Navbar.defaultProps = {
  popUpCallback: null
}


export default Navbar
