import React from 'react'
import PropTypes from 'prop-types'
import ConsultationForm from './ConsultationForm'
import FormConfrimation from './FormConfrimation'
import Lines from '../images/lines.inline.svg'
import { PopUp } from '@brightoak//embrk-react-components'

const FormSection = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConf: false
    };
  }

  componentDidMount() {
    if (window.location.href.indexOf("form-submitted") > -1 && !this.state.showConf) {
      // window.gtag('event', 'conversion', {'send_to': 'AW-636876801/Q2j8COzRvdABEIHw168C'});
      this.setState({showConf: true});
      this.props.forceShowPupUp && this.props.forceShowPupUp();
    } else if (this.props.checkForSingUp && window.location.href.indexOf("signup") > -1 && !this.state.forceShowPopUp) {
      this.props.forceShowPupUp && this.props.forceShowPupUp();
    }
  }

  render() {
    return (
      <div className="form-section">
        <PopUp forceShowPopUp={this.props.showPupUp} forceClosePopUp={() => this.props.forceClosePopUp()} >
          <div className="pop-up-container">
            { this.state.showConf || this.props.forceShowConf  ?
                <FormConfrimation closeClallback={ () => this.props.forceClosePopUp() }/> :
                <div>
                  <div className="form-header">
                    <h2>Sign up for 1 FREE Class!</h2>
                    <p>Please complete the from below and someone from the K Effect team will get right back to you.</p>
                  </div>
                <ConsultationForm onSubmitCallback={ () => this.setState({showConf: true}) }/>
              </div>
            }
            <Lines/>
          </div>
        </PopUp>
      </div>
    )
  }
}

FormSection.propTypes = {
    showPupUp: PropTypes.bool,
    forceShowPupUp: PropTypes.func,
    forceShowConf: PropTypes.bool,
    forceClosePupUp: PropTypes.func,
    checkForSingUp: PropTypes.bool,
}

export default FormSection
