import React from 'react'
import { Link } from 'react-scroll'
import Logo from '../images/logo.inline.svg'
import PropTypes from 'prop-types'
import { FaRegEnvelope} from 'react-icons/fa'

const BottomNav = class extends React.Component {
  state = {
    active: false
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      }
    )
  }

  scrollLinkClicked = () => {
    if (window.location.pathname != "/" && window.location.pathname != "" ) {
      window.location.href = window.location.origin
    }
  }

  addressLinkClicked = () => {
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let Android = /(android)/i.test(navigator.userAgent);

    if (iOS) {
      window.open("maps://maps.apple.com/q=1140 Brickyard Road, Suite 32, SLC, Utah 84106");
    } else if (Android) {
      window.open("maps://maps.google.com/maps?q=1140 Brickyard Road, Suite 32, SLC, Utah 84106");
    } else {
      window.open("https://goo.gl/maps/UemXXwk6e3Zp7n4W8");
    }

  }

  render() {
    return (
      <nav
        className="navbar navbar-bottom is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Logo/>
          </div>
          <div
            id="navMenu"
            className="navbar-menu"
          >
            <div className="navbar-end has-text-centered">
              <div className="links">
                <Link to="scroll-bands" smooth={true} onClick={() => this.scrollLinkClicked()}>
                  <span id="scroll-link-bands" className="page-scroll-link">K Effect Bands</span>
                </Link>
                <Link to="scroll-app" smooth={true} onClick={() => this.scrollLinkClicked()}>
                  <span id="scroll-link-app" className="page-scroll-link"> K Effect App</span>
                </Link>
                <Link to="scroll-k-effect" smooth={true} onClick={() => this.scrollLinkClicked()}>
                  <span id="scroll-link-k-effect" className="page-scroll-link">The Köhler Effect</span>
                </Link>
                <div className="verticle-divide"></div>
                <a className="phone-number" href="tel:801-893-7328">801.893.7328</a>
                <a className="company-email" href="mailto:brickyard@keffecttraining.com"><FaRegEnvelope/></a>
              </div>
            </div>
          </div>
        </div>
        <div className="address-row">
          <a className="desktop" href="https://goo.gl/maps/UemXXwk6e3Zp7n4W8" target="_blank"> 1140 Brickyard Road, Suite 32, SLC, Utah 84106 </a>
          <a className="mobile" onClick={() => this.addressLinkClicked()} > 1140 Brickyard Road, Suite 32 SLC Utah 84106 </a>
        </div>
      </nav>
    )
  }
}

BottomNav.propTypes = {
  popUpCallback: PropTypes.func
}

BottomNav.defaultProps = {
  popUpCallback: null
}

export default BottomNav
